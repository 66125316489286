import React from 'react'
import logo from '../images/logos/logo.png'
import textImg from '../images/logos/worldlinx_Alliance.png'
import { css } from '@emotion/core'
import { Link, StaticQuery, graphql } from 'gatsby'
import get from 'lodash/get'
import { Card } from '../components/Card'
const pages = ['about', 'benefits', 'facilities', 'beechcraft', 'contact']
const GET_STAFF = graphql`
  {
    allStaffJson {
      edges {
        node {
          name
          job
          email
          phone
        }
      }
    }
  }
`
const StaffCard = ({ name, email, phone, job }) => (
  <Card
    css={css`
      border-radius: 10px;
      background: var(--colors-branding-lightest);
      padding: var(--spacing-sm);
      a {
        display: block;
        color: var(--colors-branding-dark);
        transition: 0.4s ease all;
        text-decoration: none;
        margin-top: var(--spacing-sm);
        &:hover {
          outline-bottom: 2px solid var(--colors-branding);
        }
      }
      h3 {
        margin-top: 0.2rem;
        margin-bottom: 0.1rem;
      }
    `}
  >
    <h3>{name}</h3>
    <small>{job}</small>
    <a href={`mailto:${email}`}>{email}</a>
    <a href={`tel:${phone.replace(' ', '')}`}>{phone}</a>
  </Card>
)
const Staff = props => {
  return (
    <div
      css={css`
        grid-gap: var(--spacing-md);
        padding: 0.4rem;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      `}
    >
      <StaticQuery
        query={GET_STAFF}
        render={({ allStaffJson: { edges: staff } }) =>
          staff.map(({ node: { name, job, email, phone } }) => (
            <StaffCard
              key={'Staff_' + phone}
              {...{ name, job, email, phone }}
            />
          ))
        }
      />
    </div>
  )
}

export default Staff
